<template>
  <div>
    <div class="header" style="padding: 0.2rem">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white; font-weight: bold"
            >个人中心</span
          >
        </van-col>
      </van-row>
    </div>
    <div style="padding: 0.2rem">
      <div class="avatar">
        <van-image
          :src="personInfo.user.avatar"
          round
          fit="cover"
          width="100"
          height="100"
          style="
            box-shadow: 0px 5px 10px 5px rgba(0, 0, 0.7, 0.1);
            z-index: 888;
          "
        />
      </div>
      <card class="info-card">
        <template #content>
          <van-rate
            v-model="personInfo.guide.guide_rate_star"
            :size="25"
            color="rgb(255, 170, 0)"
            allow-half
            void-icon="star"
            readonly
            void-color="#eee"
            style="margin-top: 50px"
          />
          <van-row style="color: black" class="info-row">
            <span style="font-weight: 600">{{ personInfo.guide.name }}</span>
          </van-row>
          <van-row class="info-row">
            性别：{{
              personInfo.guide.gender ? personInfo.guide.gender : "未完善"
            }}
          </van-row>
          <van-row class="info-row">
            语种：{{
              personInfo.guide.language ? personInfo.guide.language : "未完善"
            }}
          </van-row>
          <van-row class="info-row">
            角色：{{ personInfo.guide.role_name }}
          </van-row>
          <van-row class="info-row"
            >{{ personInfo.guide.role_name }}证号：
            <span
              :class="
                personInfo.guide.tourist_card == '' ||
                personInfo.guide.tourist_card == null
                  ? ''
                  : 'img-link'
              "
              @click="onImgClick(personInfo.guide.tourist_card_image)"
            >
              {{
                personInfo.guide.tourist_card == "" ||
                personInfo.guide.tourist_card == null
                  ? "暂无"
                  : personInfo.guide.tourist_card
              }}
            </span>
          </van-row>
          <van-row class="info-row">
            <!-- <div style="width: 20%"></div> -->
            {{ personInfo.guide.role_name }}资格证号：
            <!-- <div style="display: block; text-align: left"> -->
            <span
              :class="
                personInfo.guide.tourist_card != '' &&
                personInfo.guide.tourist_card != null
                  ? 'img-link'
                  : ''
              "
              @click="onImgClick(personInfo.guide.tourist_certificate_image)"
            >
              <splitString
                v-if="
                  personInfo.guide.tourist_certificate != null &&
                  personInfo.guide.tourist_certificate != ''
                "
                :value="personInfo.guide.tourist_certificate"
              >
              </splitString>
              <span v-else>暂无</span>
            </span>
            <!-- </div> -->
          </van-row>
          <van-row
            class="info-row"
            v-if="personInfo.guide.role_name != '讲解员'"
          >
            导游级别：{{
              personInfo.guide.level ? personInfo.guide.level : "未完善"
            }}
          </van-row>
          <van-row class="info-row" v-if="personInfo.agency !== null">
            <span>所属旅行社：</span>
            <!-- <van-button type="default" round size="small" @click="onAgencyClick()" v-if="personInfo.guide.status == 1"> -->
            <span
              @click="onAgencyClick()"
              v-if="personInfo.guide.status != 4"
              style="color: rgb(67, 109, 239); text-decoration: underline"
            >
              {{ personInfo.agency.name }}</span
            >

            <!-- </van-button> -->
            <span v-if="personInfo.guide.status == 4">无</span>
          </van-row>
          <van-row class="info-row" v-if="personInfo.agency === null">
            所属旅行社：无
          </van-row>
          <van-row style="margin-top: 10px">
            <qrCode
              :text="`${baseVistorFrontEndUrl}guider/info/${personInfo.guide.id}`"
              :logoSrc="
                personInfo.user.avatar == ''
                  ? ''
                  : `${personInfo.user.avatar}?cache`
              "
              :margin="0"
              colorDark="#333"
              colorLight="#fff"
              :logoScale="0.2"
              :size="200"
              style="padding: 20px; border-radius: 20px; background: #ebebeb"
            ></qrCode>
          </van-row>
          <van-row>
            <van-cell
              is-link
              to="/wode/tempCompanies"
              :center="false"
              style="text-align: left"
            >
              <template #title>
                <span style="color: #808080"> 查看借调旅行社 </span>
              </template>
            </van-cell>
          </van-row>
        </template>
      </card>
    </div>
    <van-popup v-model="showEmptyPickedImg">
      <van-empty image="error" description="未完善" />
    </van-popup>
    <!-- <div class="submit action_bg">
      <van-button @click="sign_out" color="#4168EE" type="info" class="action_btn">退出登录</van-button>
    </div> -->
  </div>
</template>

<script>
import qrCode from "vue-qr";
import card from "@/views/components/Card";
import { mapGetters } from "vuex";
import { Dialog } from "vant";
import { baseVistorFrontEndUrl } from "@/utils/constants";
import { ImagePreview } from "vant";
import splitString from "@/views/components/SplitString";
export default {
  components: {
    qrCode,
    card,
    splitString,
  },
  data() {
    return {
      baseVistorFrontEndUrl: baseVistorFrontEndUrl,
      showEmptyPickedImg: false,
      list: [
        // {
        //   title: "安全",
        //   icon: require("@/assets/anquan.png"),
        // },
        {
          title: "个人信息设置",
          icon: require("@/assets/shezhi.png"),
          to: "/wode/edit",
        },
        // {
        //   title: "分享名片",
        //   icon: require("@/assets/fenxiang.png"),
        // },
        // {
        //   title: "关于",
        //   icon: require("@/assets/guanyu.png"),
        // },
      ],
      fenxiangIcon: require("@/assets/share.png"),
      touxiang: require("@/assets/touxiang.png"),
    };
  },
  methods: {
    sign_out() {
      const _self = this;
      Dialog.confirm({
        title: "操作提醒",
        message: "退出当前账号？",
      }).then(() => {
        _self.$store.dispatch("wode/resetState");
        _self.$store.dispatch("travel_list/resetState");
        _self.$router.push("/login");
      });
    },
    onClickBack() {
      this.$router.push({ path: "/home" });
    },
    onAgencyClick() {
      this.$router.push(`/wode/company/${this.personInfo.agency.id}`);
    },
    onImgClick(url) {
      if (url == "") {
        this.showEmptyPickedImg = true;
      } else {
        ImagePreview(url.split(","));
      }
    },
  },
  computed: {
    ...mapGetters("wode", {
      personInfo: "getPersonInfo",
    }),
  },
  created() {
    this.$store.dispatch("wode/getPersonInfo");
  },
};
</script>

<style>
.wode-header {
  height: 110px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}
.share-icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  padding: 5px;
  background: #fff;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;
}
.fenxiang-img {
  width: 80px;
}

.info-card {
  margin-top: -50px;
  padding-bottom: 4rem;
  font-size: 14px;
  color: grey;
  text-align: center;
  margin-bottom: 100px;
  z-index: 100;
  overflow-y: scroll;
  height: 70vh;
}
.info-row {
  margin-top: 10px;
}
.avatar {
  margin-top: 0.2rem;
  text-align: center;
  width: 100%;
  display: block;
  z-index: 200;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
  z-index: 999;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 555;
}
.img-link {
  color: #436def;
  text-decoration: underline;
}
</style>