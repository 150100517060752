var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header",staticStyle:{"padding":"0.2rem"}},[_c('van-row',{attrs:{"gutter":"10"}},[_c('van-col',{on:{"click":_vm.onClickBack}},[_c('svg-icon',{staticStyle:{"height":"25px"},attrs:{"data_iconName":'back-default',"className":'back_icon'}}),_c('span',{staticStyle:{"font-size":"25px","color":"white","font-weight":"bold"}},[_vm._v("个人中心")])],1)],1)],1),_c('div',{staticStyle:{"padding":"0.2rem"}},[_c('div',{staticClass:"avatar"},[_c('van-image',{staticStyle:{"box-shadow":"0px 5px 10px 5px rgba(0, 0, 0.7, 0.1)","z-index":"888"},attrs:{"src":_vm.personInfo.user.avatar,"round":"","fit":"cover","width":"100","height":"100"}})],1),_c('card',{staticClass:"info-card",scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('van-rate',{staticStyle:{"margin-top":"50px"},attrs:{"size":25,"color":"rgb(255, 170, 0)","allow-half":"","void-icon":"star","readonly":"","void-color":"#eee"},model:{value:(_vm.personInfo.guide.guide_rate_star),callback:function ($$v) {_vm.$set(_vm.personInfo.guide, "guide_rate_star", $$v)},expression:"personInfo.guide.guide_rate_star"}}),_c('van-row',{staticClass:"info-row",staticStyle:{"color":"black"}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.personInfo.guide.name))])]),_c('van-row',{staticClass:"info-row"},[_vm._v(" 性别："+_vm._s(_vm.personInfo.guide.gender ? _vm.personInfo.guide.gender : "未完善")+" ")]),_c('van-row',{staticClass:"info-row"},[_vm._v(" 语种："+_vm._s(_vm.personInfo.guide.language ? _vm.personInfo.guide.language : "未完善")+" ")]),_c('van-row',{staticClass:"info-row"},[_vm._v(" 角色："+_vm._s(_vm.personInfo.guide.role_name)+" ")]),_c('van-row',{staticClass:"info-row"},[_vm._v(_vm._s(_vm.personInfo.guide.role_name)+"证号： "),_c('span',{class:_vm.personInfo.guide.tourist_card == '' ||
              _vm.personInfo.guide.tourist_card == null
                ? ''
                : 'img-link',on:{"click":function($event){return _vm.onImgClick(_vm.personInfo.guide.tourist_card_image)}}},[_vm._v(" "+_vm._s(_vm.personInfo.guide.tourist_card == "" || _vm.personInfo.guide.tourist_card == null ? "暂无" : _vm.personInfo.guide.tourist_card)+" ")])]),_c('van-row',{staticClass:"info-row"},[_vm._v(" "+_vm._s(_vm.personInfo.guide.role_name)+"资格证号： "),_c('span',{class:_vm.personInfo.guide.tourist_card != '' &&
              _vm.personInfo.guide.tourist_card != null
                ? 'img-link'
                : '',on:{"click":function($event){return _vm.onImgClick(_vm.personInfo.guide.tourist_certificate_image)}}},[(
                _vm.personInfo.guide.tourist_certificate != null &&
                _vm.personInfo.guide.tourist_certificate != ''
              )?_c('splitString',{attrs:{"value":_vm.personInfo.guide.tourist_certificate}}):_c('span',[_vm._v("暂无")])],1)]),(_vm.personInfo.guide.role_name != '讲解员')?_c('van-row',{staticClass:"info-row"},[_vm._v(" 导游级别："+_vm._s(_vm.personInfo.guide.level ? _vm.personInfo.guide.level : "未完善")+" ")]):_vm._e(),(_vm.personInfo.agency !== null)?_c('van-row',{staticClass:"info-row"},[_c('span',[_vm._v("所属旅行社：")]),(_vm.personInfo.guide.status != 4)?_c('span',{staticStyle:{"color":"rgb(67, 109, 239)","text-decoration":"underline"},on:{"click":function($event){return _vm.onAgencyClick()}}},[_vm._v(" "+_vm._s(_vm.personInfo.agency.name))]):_vm._e(),(_vm.personInfo.guide.status == 4)?_c('span',[_vm._v("无")]):_vm._e()]):_vm._e(),(_vm.personInfo.agency === null)?_c('van-row',{staticClass:"info-row"},[_vm._v(" 所属旅行社：无 ")]):_vm._e(),_c('van-row',{staticStyle:{"margin-top":"10px"}},[_c('qrCode',{staticStyle:{"padding":"20px","border-radius":"20px","background":"#ebebeb"},attrs:{"text":`${_vm.baseVistorFrontEndUrl}guider/info/${_vm.personInfo.guide.id}`,"logoSrc":_vm.personInfo.user.avatar == ''
                ? ''
                : `${_vm.personInfo.user.avatar}?cache`,"margin":0,"colorDark":"#333","colorLight":"#fff","logoScale":0.2,"size":200}})],1),_c('van-row',[_c('van-cell',{staticStyle:{"text-align":"left"},attrs:{"is-link":"","to":"/wode/tempCompanies","center":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticStyle:{"color":"#808080"}},[_vm._v(" 查看借调旅行社 ")])]},proxy:true}])})],1)]},proxy:true}])})],1),_c('van-popup',{model:{value:(_vm.showEmptyPickedImg),callback:function ($$v) {_vm.showEmptyPickedImg=$$v},expression:"showEmptyPickedImg"}},[_c('van-empty',{attrs:{"image":"error","description":"未完善"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }